<template>
  <div class="row g-4 mb-4 ml-2 mt-2" v-if="dashboard != null">
    <div class="col-6 col-lg-3">
      <div class="app-card app-card-stat shadow-sm h-100">
        <div class="app-card-body p-3 p-lg-4">
          <h4 class="stats-type mb-1">Total Patient</h4>
          <div class="stats-figure">{{ dashboard.totalPatient }}</div>
        </div>
        <!--//app-card-body-->
        <a class="app-card-link-mask" href="#"></a>
      </div>
      <!--//app-card-->
    </div>
    <div class="col-6 col-lg-3">
      <div class="app-card app-card-stat shadow-sm h-100">
        <div class="app-card-body p-3 p-lg-4">
          <h4 class="stats-type mb-1">Total Prescription</h4>
          <div class="stats-figure">{{ dashboard.totalPrescription }}</div>
        </div>
        <!--//app-card-body-->
        <a class="app-card-link-mask" href="#"></a>
      </div>
      <!--//app-card-->
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      dashboard: null,
    };
  },
  async created() {
    await this.$store.dispatch("auth/getDashboard");
    this.dashboard = await this.$store.getters["auth/dashboard"];
  },
};
</script>
